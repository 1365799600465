import React, { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import { useDispatch, useSelector } from "react-redux"
import dynamic from "next/dynamic"
import { getTranslations } from "@/components/SaveToList/localization"
import Image from "@/components/Shared/Image"
import { SAVE_TO_LIST_NAME_FIELD_REGEX } from "@/constants/regex"
import {
  addProductToList,
  getMyAccountLists,
} from "@/store/features/favoritesSlice"
import { selectGenericState } from "@/store/features/genericSlice"
import { getIsNameDuplicate, getUserCreatedLists } from "@/utils/helper"
import CloseIcon from "@/public/icons/modal-close.svg"
import Styles from "@/components/SaveToList/index.module.scss"
import {
  dataLayerCreateNewSaveListButton,
  dataLayerMyListSaveListButton,
  dataLayerCreateNewListButton,
  closeSaveToListAnalytics,
  closeSelectMyListAnalytics,
  closeCreateNewListAnalytics,
  closeCreateNewListSuccessAnalytics,
  closeSelectMyListSuccessAnalytics,
} from "@/components/SaveToList/analytics"

const ListForm = dynamic(() => import("@/components/SaveToList/ListForm"))
const CreateForm = dynamic(() => import("@/components/SaveToList/CreateForm"))

const SaveToList = props => {
  const { t } = useTranslation("common")
  const staticText = getTranslations(t)
  const {
    globalConfig: {
      internationalization: { locale: newLocale = "" } = {},
    } = {},
  } = useSelector(selectGenericState)
  const {
    productSku = "",
    showListSaveAndCreate = false,
    createList = false,
    listSaveAndCreateClose = () => {},
    handleSuccessCallBack = () => {},
    getProductInfo = () => {},
  } = props || {}

  const dispatch = useDispatch()
  const [saveBtnStatus, setSaveBtnStatus] = useState(true)
  const [createNewList, setCreateNewList] = useState(createList)
  const [headerText, setHeaderText] = useState("")
  const [decText, setDecText] = useState("")
  const [userLists, setUserLists] = useState([])
  const [listNameValidationList, setListNameValidationList] = useState([])
  const [nameValidationErrorMsg, setNameValidationErrorMsg] = useState("")
  const [showNameValidationError, setShowNameValidationError] = useState(false)
  const [newListName, setNewListName] = useState("")
  const [projectName, setProjectName] = useState("")
  const [dec, setDec] = useState("")
  const [selectedList, setSelectedList] = useState("")
  const [createListData, setCreateListData] = useState({})
  const [selectedListData, setSelectedListData] = useState({})
  const [createListAddItemsSuccess, setCreateListAddItemsSuccess] =
    useState(false)
  const [count, setCount] = useState(0)
  const [listNamesLoader, setListNamesLoader] = useState(true)

  const productInfo = JSON.parse(getProductInfo("", false, false))

  const getUserCreatedListData = () => {
    dispatch(getMyAccountLists())
      .unwrap()
      .then(response => {
        const locale = newLocale === "en-CA" ? "en-US" : newLocale
        const data = getUserCreatedLists(response, locale)
        setUserLists(data?.lists)
        setListNameValidationList(data?.listNames)
        setListNamesLoader(false)
      })
      .catch(error => {})
  }

  const listNameValidation = e => {
    let value = e?.target?.value
    value = value?.length < 37 ? value : value.slice(0, 36)
    setNewListName(value)
    setNameValidationErrorMsg(staticText.createNameError)
    if (
      value?.trim().length < 3 ||
      value?.trim().length > 36 ||
      !SAVE_TO_LIST_NAME_FIELD_REGEX.test(value)
    ) {
      setNameValidationErrorMsg(staticText.createNameError)
      setShowNameValidationError(true)
    } else if (getIsNameDuplicate(value.trim(), listNameValidationList)) {
      setNameValidationErrorMsg(staticText.createSameNameError)
      setShowNameValidationError(true)
    } else {
      setShowNameValidationError(false)
    }
  }
  const projectNameValidation = e => {
    const value = e?.target?.value
    value?.length < 37
      ? setProjectName(value)
      : setProjectName(value.slice(0, 36))
  }

  const handleSelectedList = list => {
    setSelectedList(list?.id)
    setSelectedListData(list)
  }

  const closeModalHandle = () => {
    if (saveBtnStatus && !createNewList) {
      const pageType = "plp"
      closeSaveToListAnalytics(productInfo, pageType)
    }
    if (!saveBtnStatus && !createNewList) {
      const pageType = "plp"
      const selectedData = selectedListData.name
      closeSelectMyListAnalytics(productInfo, pageType, selectedData)
    }
    if (createListAddItemsSuccess && !saveBtnStatus && !createNewList) {
      const pageType = "plp"
      const selectedData = selectedListData.name
      const successTitle = staticText?.saveToListActionTitle
      closeSelectMyListSuccessAnalytics(
        productInfo,
        pageType,
        selectedData,
        successTitle
      )
    }
    if (saveBtnStatus && createNewList) {
      const pageType = "plp"
      const createNewListTitle = staticText?.saveToListCreateNewBtn
      closeCreateNewListAnalytics(productInfo, pageType, createNewListTitle)
    }
    if (createListAddItemsSuccess && !saveBtnStatus && createNewList) {
      const pageType = "plp"
      const createListData = newListName
      const createNewListTitle = staticText?.saveToListCreateNewBtn
      const successTitle = staticText?.saveToListActionTitle
      closeCreateNewListSuccessAnalytics(
        productInfo,
        pageType,
        createListData,
        createNewListTitle,
        successTitle
      )
    }
    listSaveAndCreateClose()
  }

  const handleCreateNewList = () => {
    const pageType = "plp"
    const createNewListTitle = staticText?.saveToListCreateNewBtn
    dataLayerCreateNewListButton(productInfo, pageType, createNewListTitle)
    setSelectedList("")
    setCreateNewList(true)
    setSaveBtnStatus(true)
  }

  const handleDec = e => {
    const { value } = e.target
    setCount(value?.length)
    setDec(value)
  }
  const handleCreateListAndAddProductsToIt = async () => {
    if (createListAddItemsSuccess) {
      listSaveAndCreateClose()
      return
    }
    setSaveBtnStatus(true)

    const listInfo = {}
    const list = {}
    if (createNewList) {
      const pageType = "plp"
      const createListData = newListName
      const successDesc = staticText?.saveEventMsgCreateList
      const createNewListTitle = staticText?.saveToListCreateNewBtn
      const successTitle = staticText?.saveToListActionTitle
      listInfo["name"] = newListName?.trim()
      listInfo["project"] = projectName
      listInfo["description"] = dec
      dataLayerCreateNewSaveListButton(
        productInfo,
        pageType,
        createListData,
        successDesc,
        createNewListTitle,
        successTitle
      )
    } else {
      const pageType = "plp"
      const selectedData = selectedListData.name
      const successDesc = staticText?.saveEventMsgMyList
      const successTitle = staticText?.saveToListActionTitle
      list["id"] = selectedListData?.id
      list["version"] = selectedListData?.version
      dataLayerMyListSaveListButton(
        productInfo,
        pageType,
        selectedData,
        successDesc,
        successTitle
      )
    }
    dispatch(
      addProductToList({ sku: productSku, list: list, listData: listInfo })
    )
      .unwrap()
      .then(async response => {
        const locale = newLocale === "en-CA" ? "en-US" : newLocale
        setCreateListData({
          name: response?.name[locale],
          version: response?.version,
          id: response?.id,
        })
        setHeaderText(staticText?.saveToListActionTitle)
        setDecText(staticText?.saveToListActionDec)
        setCreateListAddItemsSuccess(true)
        setSaveBtnStatus(false)
        handleSuccessCallBack()
      })
      .catch(errror => {})
  }

  useEffect(() => {
    getUserCreatedListData()
  }, [])

  useEffect(() => {
    document.body.style.overflow = showListSaveAndCreate ? "hidden" : "auto"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [showListSaveAndCreate])

  useEffect(() => {
    if (
      (createNewList && !showNameValidationError) ||
      (!createNewList && selectedList)
    ) {
      setSaveBtnStatus(false)
    } else {
      setSaveBtnStatus(true)
    }
  }, [newListName, selectedList])

  useEffect(() => {
    if (createNewList) {
      setHeaderText(staticText?.saveToListCreateHeader)
      setDecText(staticText?.saveToListCreateDec)
    } else {
      setHeaderText(staticText?.saveToListSaveHeader)
      setDecText(staticText?.saveToListSaveDec)
    }
    setSaveBtnStatus(true)
  }, [createNewList])

  useEffect(() => {
    let timerConst
    if (createListAddItemsSuccess) {
      timerConst = setTimeout(() => {
        listSaveAndCreateClose()
      }, 3000)
    }
    return () => {
      clearTimeout(timerConst)
    }
  }, [createListAddItemsSuccess])

  if (listNamesLoader && showListSaveAndCreate) {
    return (
      <div className={Styles.SaveToListWrapper}>
        <div className="save-to-list-modal">
          <div className="loading-container">
            <div className="loading-text">{staticText?.saveToListLoading}</div>
          </div>
        </div>
      </div>
    )
  }

  return showListSaveAndCreate ? (
    <div className={Styles.SaveToListWrapper}>
      <div className="save-to-list-modal">
        <div className="modal-body">
          <div className="back-close-btn">
            <div className="close-btn" onClick={closeModalHandle}>
              <Image
                src={CloseIcon?.src}
                role="presentation"
                alt="close"
                width="30"
                height="30"
              />
            </div>
          </div>
          <div className="modal-content">
            <div className="list-header">{headerText}</div>
            <div className="list-dec">{decText}</div>
            {!createNewList && !createListAddItemsSuccess ? (
              <div className="create-new-list" onClick={handleCreateNewList}>
                {staticText?.saveToListCreateNewBtn}
              </div>
            ) : null}
            {createListAddItemsSuccess ? (
              <div className="create-new-list">{createListData?.name}</div>
            ) : null}
            {!createListAddItemsSuccess && createNewList ? (
              <CreateForm
                listNameValidation={listNameValidation}
                newListName={newListName}
                showNameValidationError={showNameValidationError}
                nameValidationErrorMsg={nameValidationErrorMsg}
                projectNameValidation={projectNameValidation}
                projectName={projectName}
                dec={dec}
                handleDec={handleDec}
                count={count}
              />
            ) : null}
            {!createListAddItemsSuccess && !createNewList ? (
              <ListForm
                createNewList={createNewList}
                createListAddItemsSuccess={createListAddItemsSuccess}
                userLists={userLists}
                handleSelectedList={handleSelectedList}
                selectedList={selectedList}
              />
            ) : null}
          </div>
        </div>

        <div className="action-btn">
          <button
            className="action-btn-item"
            disabled={saveBtnStatus}
            onClick={handleCreateListAndAddProductsToIt}
          >
            <span className="action-btn-item-txt">
              {createListAddItemsSuccess
                ? staticText?.saveToListActionSaved
                : staticText?.saveToListActionSave}
            </span>
          </button>
        </div>
      </div>
    </div>
  ) : null
}
export default SaveToList
